<template>
  <div class="wrapper">

    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
      <span style="margin-left: 20px;"></span>
      <p>결과지 생성중 ...</p>
    </div>

    <div class="header_wrapper">
      <h1 class="title_wrapper">
        {{ $t("gamesets.PDF_DATA") }}
      </h1>
      <v-btn
          color="primary"
          class="btn"
          @click="handleRedirect('gamepdf')"
      >
        {{ $t("gamesets.TO_PDF") }}
      </v-btn>
    </div>


    <div v-if="!isLoading" class="card_wrapper">
      <GamesetCard :card-data="cardData">
        <template #buttons>
          <div class="row">


          </div>
        </template>
      </GamesetCard>
    </div>

    <v-row>
      <v-col cols="3">
        <v-btn @click="downloadSelected"  color="success" dense>
          PDF 전체 생성 요청
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="!isLoading">
      <v-form>
        <v-row>
          <v-col cols="3">
            <v-slider
                v-model="score1"
                :label="'Domain P: ' + score1"
                min="0"
                max="100"
                dense
                outlined
            />
          </v-col>
          <v-col cols="3">
            <v-slider
                v-model="score2"
                :label="'Domain N: ' + score2"
                min="0"
                max="100"
                dense
                outlined
            />
          </v-col>


        </v-row>
        <v-row>
          <v-col cols="3">
            <v-slider
                v-model="score3"
                :label="'C 작업기억력: ' + score3"
                min="0"
                max="100"
                dense
                outlined
            />
          </v-col>
          <v-col cols="3">
            <v-slider
                v-model="score4"
                :label="'C 효율적문제: ' + score4"
                min="0"
                max="100"
                dense
                outlined
            />
          </v-col>
        </v-row>

        <v-row>

          <v-col cols="3">
            <v-slider
                v-model="score5"
                :label="'Domain S: ' + score5"
                min="0"
                max="100"
                dense
                outlined
            />
          </v-col>

          <v-col cols="3">
            <v-slider
                v-model="score6"
                :label="'Domain R : ' + score6"
                min="0"
                max="100"
                dense
                outlined
            />
          </v-col>


        </v-row>
        <v-row style="margin-bottom: 30px;">
          <v-col cols="2">
            <v-btn @click="showScores" outlined dense>
              평균 점수 설정하기
            </v-btn>
          </v-col>
        </v-row>

      </v-form>
    </div>

    <div class="search_wrapper">
      <Search :label="$t('lookupPlaceholder.DEFAULT')"/>
    </div>
    <v-app v-if="!isLoading">
      <FilterList/>
      <div class="checkbox_wrapper">
        <Checkbox
            :value="hideOutlier"
            :label="$t('gamesets.HIDE_OUTLIER')"
            @input="handleUpdateCheckbox"
        />
      </div>
      <v-data-table
          v-if="gameHeaders && dataList.length"
          class="elevation-1"
          dense

          :headers="[...headers, ...gameHeaders]"
          :items="dataList"
          :options.sync="sortOptions"
          :hide-default-footer="true"
          :server-items-length="dataList.length"
      >
        <template
            v-for="header in gameHeaders"
            #[`item.${header.value}`]="{ item }"
        >
          <div
              :key="header.text"
              :class="isSummaryOutlier(item.summary[header.index]) && 'bg_color'"
          >
            {{ item.summary[header.index][header.text] }}
          </div>
        </template>

        <template #[`item.result_file_path`]="{ item }">
          <div v-if="item.result_file_path == null">
            <v-btn
                class="blue-grey lighten-4"
                @click="generatePDF(item)"
            >
              다운로드
            </v-btn>
          </div>
          <div v-else>
            <v-btn
                class="blue lighten-4"
                @click="downloadPDF(item)"
            >

              다운로드
            </v-btn>
          </div>
        </template>


      </v-data-table>
      <Pagination/>
      <div class="button-container">
        <Excel @click="fetchExcelFile"/>
      </div>


    </v-app>
  </div>


</template>

<script>
import GamesetCard from "@/components/gameset/GamesetCard"
import Checkbox from "@/components/common/formControls/Checkbox"
import table from "@/mixins/table"
import excel from "@/mixins/excel"
import param from "@/mixins/param"
import sort from "@/mixins/sort"
import {
  getExcelDataByGamesetId,
  getFaceStudyByGamesetId, getPdfFile, setTeamScore, getTeamScore, getPDFByGamesetId, getPdfFileBatch,
} from "@/api/exportExcel"
import {getCurrentDate,} from "@/utils/date"
import {MENU, CATEGORY,} from "@/constants/mapVarNames"
import * as accountsApi from "@/api/accounts";

export default {
  components: {
    GamesetCard,
    Checkbox,
  },
  mixins: [table, excel, sort, param],
  data() {

    const basePropsHeader = [
      {
        class: "table_header_xs",
        text: this.$t("headers.ID"),
        value: "id",
        sortable: false,
      },

      {
        class: "table_header_sm",
        text: "PDF 다운로드",
        value: "result_file_path",
        sortable: false,
      },

      {
        class: "table_header_xs",
        text: "username",
        value: "user.username",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "name",
        value: "user.name",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "age",
        value: "user.age",
        sortable: false,
      },
      {
        class: "table_header_xs",
        text: "gender",
        value: "user.gender",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "experience",
        value: "user.experience",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "position",
        value: "user.position",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "funnel",
        value: "user.funnel",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "education",
        value: "user.education",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "데이터 신뢰도",
        value: "result_index.reliability",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "배정 도메인",
        value: "result_index.domain",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "위험지각 역량",
        value: "result_index.p_care",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "사회적 민감성",
        value: "result_index.n_care",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "반복된 시행착오",
        value: "result_index.c_care",
        sortable: false,
      },


      {
        class: "table_header_sm",
        text: "대인 스트레스",
        value: "result_index.s_care",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "번아웃 위험성",
        value: "result_index.r_care",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "risk taking",
        value: "result_index.p_score_c",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "anxiety",
        value: "result_index.n_score_c",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "C 종합점수",
        value: "result_index.c_score_c",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "recognition",
        value: "result_index.s_score_c",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "effort regulation",
        value: "result_index.r_score_c",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "processing",
        value: "result_index.c_w_score_c",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "planning",
        value: "result_index.c_p_score_c",
        sortable: false,
      }

    ]

    return {
      hideOutlier: false,
      filterItems: {
        division: null,
      },
      headers: [...basePropsHeader],
      gameHeaders: null,
      faceStudyIndex: -1,
      sortOptions: {},
      score1: 0,
      score2: 0,
      score3: 0,
      score4: 0,
      score5: 0,
      score6: 0,
      errorMessage: '',
      isLoading: false,
      timer: '',
      isReload: false,
    }
  },
  computed: {


    gameset() {
      return this.$store.state.lookup.gamesets.currentItem
    },
    games() {
      return this.gameset.template.games
    },
    cardData() {
      const gameset = `${this.gameset.id} - ${this.gameset.name}`
      let company_name = ""
      let username = ""

      if (this.gameset.user) {
        company_name = this.gameset.user.company_name
        username = this.gameset.user.username
      }

      return {
        company_name,
        username,
        gameset: gameset ? gameset : "",
        template: gameset ? gameset : "",
      }
    },
  },
  watch: {
    sortOptions: {
      handler(value) {
        if (value.sortBy[0]) {
          this.$_sort_handleSort(value.sortBy[0])
        }
      },
      deep: true,
    },
  },
  async created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", {menu: MENU.gamesets, category: CATEGORY.data,})
    this.$store.dispatch("setFilterItems", this.filterItems)
    this.$store.commit("SET_SORT", null)
    this.$store.commit("SET_HIDE_OUTLIER", false)
    await this.$store.dispatch("fetchLookupData")
    this.isReload = false
    if (this.dataList.length) {
      this.handleSetGameProps()
    }
    this.timer = setInterval(this.fetchEventsList, 5000)
    this.findFaceStudyIndex()
  },

  beforeRouteLeave (to, from, next) {
    console.log('beforeleave')
    console.log(to)

    if (to.name == "gameDetail") {
      this.handleRedirect('gamepdf')
      return
    }
    this.isReload = false
    this.cancelAutoUpdate()
    next()
  },

  mounted() {
    this.initScore()
  },
  methods: {
    async initScore() {
      try {
        let response = await getTeamScore(this.gameset.id)
        this.score1 = response.data.data.default_team_score.p_score_c
        this.score2 = response.data.data.default_team_score.n_score_c
        this.score3 = response.data.data.default_team_score.c_w_score_c
        this.score4 = response.data.data.default_team_score.c_p_score_c
        this.score5 = response.data.data.default_team_score.s_score_c
        this.score6 = response.data.data.default_team_score.r_score_c
      } catch (error) {
      }

    },

    async fetchEventsList() {
      if (this.isReload){
        console.log('reload')
        await this.$store.dispatch("fetchLookupData")
        // let response = await getGameSetPDFUrlByGamesetId(this.gameset.id)
      }

    },
    cancelAutoUpdate() {
      clearInterval(this.timer)
    },


    async showScores() {

      const gamesetData = {
        default_team_score: {
          p_score_c: this.score1,
          n_score_c: this.score2,
          c_w_score_c: this.score3,
          c_p_score_c: this.score4,
          s_score_c: this.score5,
          r_score_c: this.score6
        }
      }

      try {
        let response = await setTeamScore(gamesetData, this.gameset.id)
        alert('평균 점수가 설정 되었습니다.')
        await this.$store.dispatch("fetchLookupData")

      } catch (error) {
      }


    },
    async downloadSelected() {
      //
      // let ids = []
      // for (let item in this.selected[0]){
      //   ids.push(item.id)
      // }
      this.isReload = true
      const gamesetData = {
        gameset_id: this.gameset.id,
        gameset_data_id_list: []
      }

      try {
        this.isLoading = true
        let response = await getPdfFileBatch(gamesetData)


        alert("pdf 전체 생성을 시작합니다.")
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    },
    findFaceStudyIndex() {
      const games = this.gameset.template.games

      for (let i = 0; i < games.length; i++) {
        if (games[i].key === "face") {
          this.faceStudyIndex = i
          break
        }
      }
    },
    isSummaryOutlier(summary) {
      return summary[`${summary.key}_percentile`] === -1
    },
    handleRedirect(routeName) {
      this.$router.push({name: routeName,})
    },
    handleClickGame(gameKey) {
      this.$router.push({name: "gamesetGame", params: {game_key: gameKey,},})
    },
    handleUpdateCheckbox(isChecked) {
      this.hideOutlier = isChecked
      this.$store.commit("SET_HIDE_OUTLIER", isChecked)
      this.$store.dispatch("fetchLookupData")
    },
    handleSetGameProps() {
      const summaryList = []//this.dataList[0].summary
      const isGameHeader = (key) => key !== "key"

      this.gameHeaders = summaryList.reduce((headers, summary, i) => {
        const gameProps = Object.keys(summary)
            .filter(isGameHeader)
            .map((gameProp) => ({
              index: i,
              text: gameProp,
              value: `summary[${i}].${gameProp}`,
              sortable: gameProp.includes("percentile") ? true : false,
              class: gameProp.includes("percentile") ? "table_header_lg" : "table_header_xs",
            }))

        return [...headers, ...gameProps]
      }, [])
      console.log(this.gameHeaders)
    },
    async downloadPDF(item) {

      try {
        this.downloadFile(item.result_file_path, 'test')

      } catch (error) {
      }

    },

    async generatePDF(item) {
      const gamesetData = {
        gameset_data_id: item.id
      }

      try {
        this.isLoading = true
        let response = await getPdfFile(gamesetData)
        if (response.data.data.path !== '') {
          this.downloadFile(response.data.data.path, 'test')
          item.result_file_path = response.data.data.path
        }

      } catch (error) {
      } finally {
        this.isLoading = false
      }

    },

    downloadFile(url, name) {
      // create element <a> for download PDF
      let link = document.createElement('a');
      link.href = url
      //link.target = '_blank'
      link.download = name

      // Simulate a click on the element <a>
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    downloadCSV() {
      // 데이터를 배열 형식으로 변환
      const data = this.dataList.map(item => ({
        ...item.user,
        risk_taking: item.result_index.rt,
        anxiety: item.result_index.an2,
        processing: item.result_index.pc2,
        planning: item.result_index.pl,
        recognition: item.result_index.rc2,
        effort_regulation: item.result_index.er,
        increased_risktaking: item.result_index.ir2
      }));

      // CSV 헤더 생성
      const headers = [
        'id', 'username', 'name', 'age', 'gender', 'experience', 'position', 'funnel', 'education',
        'risk_taking', 'anxiety', 'processing', 'planning', 'recognition', 'effort_regulation', 'increased_risktaking'
      ].join(',');

      // CSV 데이터 생성
      const csvRows = data.map(row =>
          Object.values(row).map(value => `"${value}"`).join(',')
      );

      // CSV 파일 내용
      const csvContent = [headers, ...csvRows].join('\n');

      // UTF-8 BOM 추가
      const utf8Bom = '\uFEFF' + csvContent;

      // CSV 파일 다운로드
      const blob = new Blob([utf8Bom], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },


    async fetchExcelFile(excelFileType) {
      try {
        console.log(this.headers)
        console.log(this.dataList)
        const params = this.$_param_createParams()
        let response

        if (excelFileType === "faceStudy") {
          response = await getPDFByGamesetId({gamesetId: this.gameset.id,})
          this.$_excel_downloadFile(response, "faceStudy")
        } else {
          response = await getPDFByGamesetId({
            gamesetId: this.gameset.id,
          })
          this.$_excel_downloadFile(response, `gameset_${CATEGORY.data}_${getCurrentDate()}`)
        }
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
  },
}
</script>

<style scoped>


.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header_wrapper {
  display: flex;
  align-items: center;
}

.card_wrapper {
  align-self: flex-start;
  margin-bottom: 1em;
}

.title_wrapper {
  margin: 1rem 0;
}

.search_wrapper {
  width: 720px;
}

.checkbox_wrapper {
  display: flex;
  align-items: center;
}

.btn {
  margin-left: 2rem;
}

.row {
  margin-top: 1em;
  display: flex;
  width: 100%;
}

.btn_container {
  width: 475px;
}

.game_btn {
  width: 9rem;
  margin-right: 1em;
  margin-bottom: 1em;
}

.bg_color {
  position: relative;
  left: -16px;
  background: red;
  color: white;
  font-weight: bolder;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: calc(100% + 32px);
}

.header {
  background: black;
  color: white;
  font-weight: bold;
  padding: 0.4em;
  padding-left: 1em;
  min-width: 100px;
}

.header_text {
  font-size: 0.9rem;
}

.table_header_xs {
  padding: 0 0.75em;
}

.table_header_sm {
  padding: 0.4em;
  min-width: 120px;
}

.table_header_md {
  padding: 0.4em;
  min-width: 160px;
}

.table_header_lg {
  padding: 0.4em;
  min-width: 200px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Add some space between the buttons if needed */
}
</style>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
